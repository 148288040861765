import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Modal,
  Tooltip,
  Collapse,
  Spin,
  Tabs,
  Card,
} from "antd";
import * as UserService from "../../Services/UserService/UserService";
import PaymentForm from "../Form/PaymentForm";
import { useDispatch } from "react-redux";
import {
  InfoCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Backdrop from "@mui/material/Backdrop";
import swal from "sweetalert";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Url } from "../../Helper";
import Http from "../../Http";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "../../Http";

const { Title } = Typography;
const { Panel } = Collapse;

const DetailsForm = ({
  handleCancel,
  showModal,
  buildingData,
  project_name,
  emirate_name,
}) => {
  const [passwordModal, setPasswordModal] = useState(false);
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  const [emailData, setEmailData] = useState();
  const [car, setCar] = useState([
    {
      id: "",
      car_make: "",
      color: "",
      source: "",
      category: "",
      parking_number: "",
      plate_code: "",
      car_model: "",
      plate_number: "",
    },
  ]);
  const [CarModal, setCarModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [plateSource, setPlateSource] = useState([]);
  const [plateCode, setPlateCode] = useState([]);
  const [carList, setCarList] = useState([]);
  const [summaryModal, setSummaryModal] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [summaryCarDetails, setSummaryCarDetails] = useState([]);
  const [selectedCode, setNewSelectedCode] = useState([]);
  const [selectedSource, setNewSelectedSource] = useState([]);
  const [selectedCar, setNewSelectedCar] = useState([]);
  const [selectedCarModal, setNewSelectedCarModal] = useState([]);
  const [existingID, setExistingID] = useState();
  const [data, setData] = useState({});
  const [buildingName, setBuildingName] = useState("");
  const [alreadyExecuted, setAlreadyExecuted] = useState(true);
  const [checkExisting, setCheckExisting] = useState(false);
  const [checkedUser, setCheckedUser] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [existingUserPassword, setExistingUserPassword] = useState(false);
  const [name, setName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [userCar, setUserCar] = useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [disabledButton, setButtonDisabled] = useState(false);
  const [actualAmount, setActualAmount] = useState();
  const [vehicleModel, setVehicleModel] = useState({});
  const [vehicleVariant, setVehicleVariant] = useState({});
  const [phone, setPhone] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [existingCustomerData, setExistingCustomerData] = useState([]);
  const [singleExecute, setSingleExecute] = useState(true);
  const [existingSummaryData, setExistingSummaryData] = useState([]);
  const [existingSummaryCar, setExistingSummaryCar] = useState([]);
  const [paymentDisabled, setPaymentDisabled] = useState(false);
  const [isRemoved, setIsRemoved] = useState(0);
  const [saveUserData, setSaveUserData] = useState(true);
  const [selectedService, setSelectedService] = useState();
  const [summaryService, setSummaryService] = useState();
  const [createAccountModal, setCreateAccountModal] = useState(false);

  let LoginId = localStorage.getItem("user_id");
  let UserEmail = localStorage.getItem("email");
  let checkPassword = localStorage.getItem("passwordEntered");
  let original_qr = localStorage.getItem("QRverify");
  let isremove = false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phoneDefaultValue = userDetails?.mobile_no
    ? userDetails?.mobile_no
    : "";
  const [updatedInformation, setUpdatedInformation] = useState([]);
  const [googleLoginUser, setGoogleLoginUser] = useState(false);
  const [googleUrl, setGoogleUrl] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [actualTip, setAcutalTip] = useState(0);
  const [description, setDescription] = useState();
  const [checked, setChecked] = useState(true);
  const [servicesList, setServicesList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(Url + "/api/services", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        setServicesList(response?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(Url + "/api/auth/google", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setGoogleUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  const googleLogin = () => {
    localStorage.setItem("google_login", "true");
  };

  // handle input change
  const handleInputChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    const list = [...car];
    list[index][name] = value;
    setCar(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    let list = [...car];
    delete list[index];
    list = list.filter((e) => e);
    let newList = list;

    isremove = true;
    setCar(newList);
    setNewSelectedCar("");
    setNewSelectedCode("");
    setNewSelectedSource("");
    setNewSelectedCarModal("");
    setVehicleVariant({});
    setIsRemoved(0);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setCar([
      ...car,
      {
        id: "",
        car_make: "",
        car_model: "",
        source: "",
        category: "",
        plate_code: "",
        parking_number: "",
        car_variant: "",
        car_year: "",
      },
    ]);
  };

  const onChangeRemember = (e) => {
    if (e.target.checked === true) {
      setPasswordModal(true);
      setRememberMe(true);
    }
  };

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    localStorage?.setItem("Amount", data?.amount);

    if (e.target.name === "amount") {
      setActualAmount(parseInt(e.target.value));
    } else if (e.target.name === "tip") {
      setAcutalTip(parseInt(e.target.value))
    } else if (e.target.name === "message") {
      setDescription(e.target.value);
    }
  };

  const handlePaymentSuccess = () => {
    setTimeout(() => {
      swal({
        title: "Your payment have been successfully processed.",
        text: "The copy of your Invoice and Receipt have been sent to your email.",
        icon: "success",
      }).then(function () {
        let user_email = email ? email : UserEmail;
        if (checkedUser === true) {
          localStorage.setItem("user_id", existingID);
          localStorage.setItem("email", user_email);
          localStorage.setItem("passwordEntered", true);
          localStorage.setItem(
            "user_name",
            userDetails?.first_name + " " + userDetails?.last_name
          );
        }
        localStorage?.removeItem("Amount");
        localStorage?.removeItem("Tip");
        localStorage?.removeItem("Desc");
        if (LoginId) {
          navigate("/customer_payment");
        } else {
          window.location.reload();
        }
      });
      setPasswordModal(false);
      setPaymentModal(false);
      setSummaryModal(false);
      setCarModal(false);
      handleCancel();
      setLoader(false);
      setOpen(false);
      setData(null);
      // window.location.reload(true);
    }, 2000);
  };

  const handleOkPassword = () => {
    setPasswordModal(false);
  };

  const handleCancelPassword = () => {
    setPasswordModal(false);
    setRememberMe(false);
  };

  const onFinishPassword = (values) => {
    setLoader(true);
    setOpen(true);
    userDetails.selectedService = selectedService ? selectedService : "";
    userDetails.qr_code = original_qr ? original_qr : "";
    if (existingCustomer === true) {
      const registerData = {
        ...userDetails,
        ...values,
        ...existingCustomerData,
      };
      dispatch(UserService.register_user(registerData))
        .then((response) => {
          setExistingID(response.id);
          closeAfter2Seconds();
          setCheckedUser(true);
          setCreateAccountModal(false);
          setIsModalOpen(false);
          localStorage.setItem("passwordEntered", true);
          swal({
            title: "Your registeration has been completed successfully",
            text: "An email has been sent to your registered email address. Please verify it to login into your account.",
            icon: "success",
            button: "Okay",
            closeOnClickOutside: false,
          }).then((response) => {
            navigate("/login");
          });
        })
        .catch((err) => {
          console.info(err);
        });
    } else {
      const registerData = { ...userDetails, ...values, ...car };
      dispatch(UserService.register_user(registerData))
        .then((response) => {
          setExistingID(response.id);
          closeAfter2Seconds();
          setCheckedUser(true);
          localStorage.setItem("passwordEntered", true);
        })
        .catch((err) => {
          console.info(err);
        });
    }
  };

  const closeAfter2Seconds = () => {
    setTimeout(() => {
      setLoader(false);
      setOpen(false);
      setPasswordModal(false);
    }, 1000); 
  };

  const onFinishFailedPassword = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const verifyEmail = (e) => {
    setEmail(e.target.value);
    localStorage.setItem("emailAddress", e.target.value);
    setLoader(true);
    setOpen(true);
    dispatch(UserService.check_existing_user(e.target.value))
      .then((response) => {
        if (response.exists === false) {
          dispatch(UserService.check_existing_customer(e.target.value))
            .then((response) => {
              if (response?.exists === true) {
                setCreateAccountModal(true);
                setExistingCustomer(true);
                setExistingCustomerData(response?.data);
              }
            })
            .catch((err) => {
              setLoader(false);
            });
          setEmailExists(false);
        }
        if (response?.googlelogin === true) {
          setGoogleLoginUser(true);
        }
        if (response?.data?.status === 0) {
          swal({
            title: "Your Account has been disabled.",
            icon: "error",
            button: "Close",
            closeOnClickOutside: false,
          });
          setLoader(false);
          setOpen(false);
          setButtonDisabled(true);
        } else {
          if (response.exists === true) {
            setExistingUserPassword(true);
            localStorage.setItem("passwordEntered", true);
            setEmailExists(true);
          }
          setLoader(false);
          setOpen(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const verifyPassword = (value) => {
    setLoader(true);
    setOpen(true);
    value.email = email;
    dispatch(UserService.check_existing_user_password(value))
      .then((response) => {
        if (response.message == "Logged In Successfully") {
          localStorage.setItem("user_id", response?.user_id);
          localStorage.setItem("token", response?.token);
          localStorage.setItem("user_name", response?.name);
          localStorage.setItem("email", response?.email);
          localStorage.setItem("passwordEntered", true);
          dispatch(UserService.check_existing_user(email)).then((response) => {
            if (response?.data?.car?.length !== 0) {
              setCar(response?.data?.car);
              setUserCar(true);
            }
            setEmailData(response.data);
            setEmailExists(false);
            window.location.reload(true);
          });
          setLoader(false);
          setOpen(false);
          setExistingUserPassword(false);
        } else if (response?.user_verify === false) {
          swal({
            title:
              "Please verify your email by clicking the link on the email you have received to access your account",
            icon: "error",
            button: "Try Again",
            closeOnClickOutside: false,
          });
          setLoader(false);
          setOpen(false);
        } else {
          swal({
            title: "Incorrect Password",
            icon: "error",
            button: "Try Again",
            closeOnClickOutside: false,
          });
          setLoader(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };


  useEffect(() => {
    verifyVehicleInfo();
    let existing_User = localStorage.getItem("user_id");
    if (existing_User !== null) {
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("passwordEntered", "true");
    }
  }, []);

  useEffect(() => {
    car.map((singlecar, i) => {
      CheckVehicle("1", i, "useEffect", singlecar?.car_make);
    });
    getDefaultVehicleModal();
  }, [car[0].car_make]);

  useEffect(() => {
    car.map((singlecar, i) => {
      CheckVehicle("1", i, "useEffect", singlecar?.car_make);
    });
    getDefaultVehicleModal();
  }, [isRemoved]);

  const getDefaultVehicleModal = () => {
    car.map((carModels, i) => {
      checkModel(
        "1",
        i,
        "useEffect",
        carModels ? carModels?.car_model : vehicleModel[i]?.name
      );
    });
  };

  const verifyVehicleInfo = (value) => {
    dispatch(UserService.get_vehicle_detail(value))
      .then((response) => {
        // setPlateCode(response?.plate_code);
        setPlateSource(response?.source);
        setCarList(response?.car);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleOkCar = () => {
    setCarModal(false);
  };

  const handleCancelCar = () => {
    setCarModal(false);
  };

  const handleOkPayment = () => {
    setPaymentModal(false);
  };

  const handleCancelPayment = () => {
    setPaymentModal(false);
  };

  const handlePhoneChange = (value) => {
    if (value !== undefined && value !== 'undefined') {
      setPhone(value)
    }
  };

  useEffect(() => {
    if (LoginId !== null || singleExecute === true) {
      setOpen(true);
      setLoader(true);
      localStorage.setItem("ExistingDataFetching", true);
      dispatch(UserService.check_existing_user(UserEmail))
        .then((response) => {
          if (response.data.car.length !== 0) {
            setCar(response.data.car);
            setExistingSummaryCar(response?.data?.car);
          }
          setEmailData(response.data);
          setCheckExisting(response?.exists);
          setExistingSummaryData(response?.data);
          setSingleExecute(false);
          var form = {
            email: response?.data?.email,
            first_name: response?.data?.first_name,
            mobile_no: response?.data?.mobile_no,
            last_name: response?.data?.last_name,
            building_name: response?.data?.building_name,
            apartment: response?.data?.apartment,
            original_qr_code: response?.data?.original_qr_code,
          };
          setUserDetails(form);
          setFirstTime(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, []);

  const checking = (event) => {
    event.preventDefault();
    if (emailExists === true) {
      swal({
        text: "An account already exists with this email address. Please try again with another email address.",
        type: "error",
        icon: "error",
      });
    } else {
      var form = {
        email: event.target.email.value,
        first_name: event.target.first_name.value,
        mobile_no: phone ? phone : phoneDefaultValue,
        last_name: event.target.last_name.value,
        building_name: event.target.building_name.value,
        apartment: event.target.apartment.value,
      };
      setBuildingName(event.target.building_name.value);
      setCarModal(true);
      setUserDetails(form);
      localStorage.setItem("first_name", event.target.first_name.value);
      localStorage.setItem("last_name", event.target.last_name.value);
      if (alreadyExecuted === true) {
        if (checkExisting === false && userCar === false) {
          setCar([
            {
              car_make: "",
              car_model: "",
              source: "",
              category: "",
              plate_code: "",
              parking_number: "",
              car_variant: "",
              car_year: "",
            },
          ]);
        }
      }
      setAlreadyExecuted(false);
    }
  };

  let key = Math.floor(Math.random() * 1000000);

  const SaveUserData = (event) => {
    event.preventDefault();
    const buttonId = event.nativeEvent.submitter.id;
    const passwordCheck = localStorage.getItem("passwordEntered");

    if (buttonId === "Pay") {
      if (checkedUser === false && firstTime === true) {
        swal({
          title: "Save time on your next checkout!",
          text: "Check the 'Remember Me' box to keep your information saved",
          icon: "info",
        });
        setFirstTime(false);
      } else {

        if (saveUserData === true) {
          setCarModal(false);
          setOpen(true);
          setLoader(true);
          handleCancel();
          event.preventDefault();
          var form = {};
          form.id = existingID;
          form.car = car;
          form.original_qr = original_qr;
          form.service_ids = selectedService
            ? selectedService
            : existingSummaryData?.service;
          const data = { ...userDetails, ...form };
          dispatch(UserService.saveUserDetails(data))
            .then((response) => {
              setSummaryData(data);
              setSummaryCarDetails(data.car);
              setOpen(false);
              setLoader(false);
              setSummaryModal(true);
              setSaveUserData(false);
              if (response.user_data) {
                localStorage.setItem("user_id", response.user_data.id)
              }

            })
            .catch((err) => {
              console.log({ err });
              setCarModal(false);
            });
        } else {
          setOpen(false);
          setLoader(false);
          setSummaryModal(true);
          setCarModal(false);
        }
      }
    } else {
      event.preventDefault();
      if (passwordCheck === "true") {
        var form = {};
        form.id = existingID;
        form.car = car;
        form.original_qr = original_qr;
        form.service_ids = selectedService
          ? selectedService
          : existingSummaryData?.service;
        const data = { ...userDetails, ...form };
        dispatch(UserService.saveUserDetails(data))
          .then((response) => {
            swal({
              title: "Your registeration has been completed successfully",
              text: "An email has been sent to your registered email address. Please verify it to login into your account.",
              icon: "info",
            }).then(function () {
              localStorage.clear();
              window.location.reload();
            });
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        swal({
          title:
            "Please choose remember me to create a password for your account.",
          icon: "info",
        });
      }
    }
  };

  const detailsForm = () => {
    handleCancelCar();
    showModal(true);
  };

  const carForm = () => {
    setPaymentModal(false);
    setSummaryModal(false);
    setCarModal(true);
  };

  const handleOkSummary = () => {
    setSummaryModal(false);
  };

  const handleCancelSummary = () => {
    setSummaryModal(false);
  };

  const paymentSection = () => {
    if (
      summaryService !== null &&
      summaryService !== undefined &&
      summaryService !== ""
    ) {
      if (LoginId !== null) {
        setPaymentModal(true);
        setSummaryModal(false);
        setIsModalOpen(false);
      } else if (termsChecked === true) {
        setPaymentModal(true);
        setSummaryModal(false);
        setIsModalOpen(false);
      }
    } else {
      swal({
        icon: "info",
        title:
          "Please select a car wash service before procedding with payment.",
      });
    }
  };

  const summaryForm = () => {
    setSummaryModal(true);
    setPaymentModal(false);
  };

  const loggedSummaryForm = () => {
    setPaymentModal(false);
  };

  const CloseForm = () => {
    handleCancel();
    setCarModal(false);
    if (LoginId === null) {
      setEmailData({});
      setUserDetails({});
      setCar([{}]);
    }
  };

  const CloseDetailForm = () => {
    setIsModalOpen(false);
    handleCancel();
  };

  const CloseCarForm = () => {
    setCarModal(false);
  };

  const CloseSummaryForm = () => {
    setSummaryModal(false);
  };

  const ClosPaymentForm = () => {
    setPaymentModal(false);
  };


  const handleSource = (event, index) => {
    let findID = plateSource?.find((item) => item.name === event.target.value);
    let data = {
      emirate_id: findID.id
    }
    console.info("findID", findID)
    dispatch(UserService.fetchPlateCodeData(data))
      .then((response) => {
        const fetchedData = response?.data || [];

        setPlateCode((prevPlateCode) => ({
          ...prevPlateCode, // Retain existing plate codes
          [findID.name]: fetchedData, // Add or overwrite codes for this plate source ID
        }));
      })
      .catch((err) => {
        console.log({ err });
      });

    const _checked = [...selectedSource];
    _checked[index] = event.target.value;
    setNewSelectedSource(_checked);
    let name = event.target.name;
    let value = event.target.value;
    const list = [...car];
    list[index][name] = value;
    setCar(list);
  };


  const handleCode = (event, index) => {
    const _checked = [...selectedCode];
    _checked[index] = event.target.value;
    setNewSelectedCode(_checked);
    let name = event.target.name;
    let value = event.target.value;
    const list = [...car];
    list[index][name] = value;
    setCar(list);
  };

  const handleCar = (event, index) => {
    const _checked = [...selectedCar];
    _checked[index] = event.target.value;
    setNewSelectedCar(_checked);
    let name = event.target.name;
    let value = event.target.value;
    const list = [...car];
    list[index][name] = value;
    setCar(list);
  };

  const handleCarModal = (event, index) => {
    const _checked = [...selectedCarModal];
    _checked[index] = event.target.value;
    setNewSelectedCarModal(_checked);
    let name = event.target.name;
    let value = event.target.value;
    const list = [...car];
    list[index][name] = value;
    setCar(list);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        lineHeight: "27px",
        color: "#212529",
        fontSize: "1.1rem",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  let amount = parseFloat(data?.amount);
  let basicAmount = actualAmount + actualTip;
  let vatAmount = (actualAmount * 5) / 100;
  let totalAmount = parseFloat(basicAmount) + parseFloat(vatAmount);

  console.info("summaryModal", summaryModal);
  console.info("selectedService", summaryModal);

  const handleSubmit = async (event) => {
    setPaymentDisabled(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setErrorMsg("");
    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    };

    let user_email = email ? email : UserEmail;
    let user_building = buildingName
      ? buildingName
      : userDetails?.building_name;
    let description = data?.message ? data?.message : '';
    let userService = summaryService && summaryService !== 1 && summaryService !== '1'
      ? summaryService
      : selectedService
        ? selectedService
        : "";
    // let original_qr_code = original_qr;
    const payment_user_info = {
      ...data,
      user_email,
      user_building,
      project_name,
      emirate_name,
      description,
      original_qr,
      userService,
    };
    const paymentMethodResult = await stripe.createPaymentMethod(
      paymentMethodObj
    );

    stripePaymentMethodHandler(
      {
        payment_data: payment_user_info,
        result: paymentMethodResult,
        amount: totalAmount,
        description: data.description ? data.description : '',
        vat: vatAmount,
        originalAmount: amount,
        userService: summaryService
          ? summaryService
          : selectedService
            ? selectedService
            : userService,
        monthly_reminder: checked === true || checked === 'true' ? 1 : 0
      },
      handleResponse
    );
  };

  const stripePaymentMethodHandler = async (data, cb) => {
    setLoader(true);
    const { payment_data, amount, result, vat, originalAmount, userService } =
      data;
    if (result.error) {
      cb(result);
    } else {
      const paymentResponse = await stripePayment({
        payment_method_id: result.paymentMethod.id,
        name: result.paymentMethod.billing_details.name,
        email: result.paymentMethod.billing_details.email
          ? result.paymentMethod.billing_details.email
          : UserEmail,
        amount: amount,
        tip: payment_data.tip ? payment_data.tip : "0",
        emirate_name: payment_data.emirate_name,
        project_name: payment_data.project_name,
        user_email: payment_data.user_email,
        user_building: payment_data.user_building,
        description: payment_data.description
          ? payment_data.description
          : "Car Wash Service",
        TotalVat: vat,
        OriginalAmount: originalAmount,
        original_qr: original_qr,
        userService: summaryService
          ? summaryService
          : selectedService
            ? selectedService
            : userService,
        monthly_reminder: checked === true || checked === 'true' ? 1 : 0
      });
      cb(paymentResponse);
    }
  };

  // place backend API call for payment
  const stripePayment = async (data) => {
    new Promise((resolve, reject) => {
      Http.post(Url + "/api/pay", data)
        .then((res) => {
          if (res.status === 200) {
            handlePaymentSuccess();
          }
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  const handleResponse = (response) => {
    if (response.error) {
      setErrorMsg(
        typeof response.error === "string"
          ? response.error
          : response.error.message
      );
      setLoader(false);
      return;
    }
    setPaymentDisabled(false);
  };

  const onChange = (e) => {
    if (e.target.checked === true) {
      setPasswordModal(true);
      setRememberMe(true);
    }
  };

  const onChangeTermsCheckbox = (e) => {
    if (termsChecked === false) {
      setTermsChecked(true);
    } else {
      setTermsChecked(false);
    }
  };

  const openPrivacyModal = () => {
    setTermsChecked(false);
    setPrivacyModal(true);
  };

  const openTermsModal = () => {
    setTermsModal(true);
  };

  const handleCancelPrivacy = () => {
    setPrivacyModal(false);
  };

  const handleOkPrivacy = () => {
    setTermsChecked(true);
    setPrivacyModal(false);
  };

  const handleCancelTerms = () => {
    setTermsModal(false);
  };

  const handleOkTerms = () => {
    setTermsChecked(true);
    setTermsModal(false);
  };

  const onChangePrivacyCollapse = (key) => {
    console.log(key);
  };

  const handleOKExistingUserPassowrd = () => {
    setExistingUserPassword(false);
  };

  const handleCancelExistingUserPassowrd = () => {
    setExistingUserPassword(false);
  };

  const onFinishFailedExistingPassword = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const CheckVehicle = (e, i, type, name) => {
    let vehicle;
    setLoader(true);
    setOpen(true);
    if (type === "useEffect") {
      vehicle = name;
    } else {
      vehicle = e.target.value;
    }
    if (type != "useEffect") {
      handleCar(e, i);
    }
    dispatch(UserService.check_vehicle(vehicle))
      .then((response) => {
        let old_data = vehicleModel;
        old_data[i] = response?.data;
        setVehicleModel(old_data);
        setLoader(false);
        setOpen(false);
        checkModel();
      })
      .catch((err) => {
        setOpen(false);
        setLoader(false);
      });
  };

  const checkModel = (e, i, type, name) => {
    setLoader(true);
    setOpen(true);
    if (isremove == true) {
    }
    let old_data1 = vehicleVariant;
    let vehicle;
    if (type === "useEffect") {
      vehicle = name;
    } else {
      vehicle = e.target.value;
    }
    if (type != "useEffect") {
      handleCarModal(e, i);
    }
    dispatch(UserService.check_vehicle_variant(vehicle))
      .then((response) => {
        key = Math.floor(Math.random() * 1000000);
        old_data1[i] = response?.data;
        setVehicleVariant(old_data1);
        setIsRemoved(1);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setOpen(false);
        setLoader(false);
      });
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  const editPersonalDetails = () => {
    setCarModal(false);
    setSummaryModal(false);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (LoginId !== null) {
      setIsModalOpen(false);
    }
  }, []);

  const onChangeReminder = (e) => {
    if (checked === true || checked === 'true') {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const renderServiceName = (val) => {
    let findServiceName = servicesList?.find((item) => parseInt(item.id) === parseInt(val));
    return findServiceName?.name;
  }

  const items = [
    {
      key: "1",
      label: `Personal Information`,
      children: (
        <div className="personalInfo">
          <Row>
            <Col xs={12} md={12}>
              <Title level={4}>First Name</Title>
              <p>{existingSummaryData?.first_name}</p>
            </Col>
            <Col xs={12} md={12}>
              <Title level={4}>Last Name</Title>
              <p>{existingSummaryData?.last_name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Title level={4}>Email</Title>
              <p>{existingSummaryData?.email}</p>
            </Col>
            <Col xs={24} md={12}>
              <Title level={4}>Mobile No.</Title>
              <p>{existingSummaryData?.mobile_no}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Title level={4}>Apartment</Title>
              <p>{existingSummaryData?.apartment}</p>
            </Col>
            <Col xs={12} md={12}>
              <Title level={4}>Building Name</Title>
              <p>{existingSummaryData?.building_name}</p>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      key: "2",
      label: `Manage Vehicles`,
      children: (
        <div className="carInfo">
          {existingSummaryCar.length <= 0 ? (
            <div className="NoVehicleSection">
              <p>No Vehicle Found</p>
            </div>
          ) : (
            existingSummaryCar.map((Car, i) => {
              return (
                <>
                  <Card className="car-Card">
                    <div className="center-aligned">
                      <Title level={4}>
                        {Car?.car_make} - {Car?.car_model}
                      </Title>
                      <Title level={4}>
                        {summaryService
                          ? renderServiceName(summaryService)
                          : selectedService
                            ? selectedService
                            : existingSummaryData?.service}
                      </Title>
                    </div>
                    <hr />
                    <div className="card-content">
                      <Row>
                        <Col xs={12} md={12}>
                          <Title level={4}>Vehicle Color</Title>
                          <p>{Car?.color}</p>
                        </Col>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Number</Title>
                          <p>{Car?.plate_number}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Source</Title>
                          <p>{Car?.source}</p>
                        </Col>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Code</Title>
                          <p>{Car?.plate_code}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} md={24}>
                          <Title level={4}>Parking Number</Title>
                          <p>
                            {Car?.parking_number ? Car?.parking_number : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </>
              );
            })
          )}
        </div>
      ),
    },
  ];

  const NewUserItems = [
    {
      key: "1",
      label: `Personal Information`,
      children: (
        <Col xs={24} md={24} className="personalInfo">
          <Row>
            <Col xs={12} md={12}>
              <Title level={4}>First Name</Title>
              <p>
                {updatedInformation.length !== 0
                  ? updatedInformation?.first_name
                  : summaryData?.first_name}
              </p>
            </Col>
            <Col xs={12} md={12}>
              <Title level={4}>Last Name</Title>
              <p>
                {updatedInformation.length !== 0
                  ? updatedInformation?.last_name
                  : summaryData?.last_name}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Title level={4}>Email</Title>
              <p>
                {updatedInformation.length !== 0
                  ? updatedInformation?.email
                  : summaryData?.email}
              </p>
            </Col>
            <Col xs={24} md={12}>
              <Title level={4}>Mobile No.</Title>
              <p>
                {updatedInformation.length !== 0
                  ? updatedInformation?.mobile_no
                  : summaryData?.mobile_no}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Title level={4}>Apartment</Title>
              <p>
                {updatedInformation.length !== 0
                  ? updatedInformation?.apartment
                  : summaryData?.apartment}
              </p>
            </Col>
            <Col xs={12} md={12}>
              <Title level={4}>Building Name</Title>
              <p>
                {updatedInformation.length !== 0
                  ? updatedInformation?.building_name
                  : summaryData?.building_name}
              </p>
            </Col>
          </Row>
        </Col>
      ),
    },
    {
      key: "2",
      label: `Manage Vehicles`,
      children: (
        <Col xs={24} md={24} className="carInfo">
          {existingSummaryCar?.length !== 0
            ? existingSummaryCar.map((Car, i) => {
              return (
                <>
                  <Card className="car-Card">
                    <div className="center-aligned">
                      <Title level={4}>
                        {Car?.car_make} - {Car?.car_model}
                      </Title>
                      <Title level={4}>
                        {selectedService ? renderServiceName(selectedService) : summaryService}
                      </Title>
                    </div>
                    <hr />
                    <div className="card-content">
                      <Row>
                        <Col xs={12} md={12}>
                          <Title level={4}>Vehicle Color</Title>
                          <p>{Car?.color}</p>
                        </Col>

                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Number</Title>
                          <p>{Car?.plate_number}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Source</Title>
                          <p>{Car?.source}</p>
                        </Col>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Code</Title>
                          <p>{Car?.plate_code}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} md={24}>
                          <Title level={4}>Parking Number</Title>
                          <p>
                            {Car?.parking_number ? Car?.parking_number : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </>
              );
            })
            : summaryCarDetails.map((Car, i) => {
              return (
                <>
                  <Card className="car-Card">
                    <div className="center-aligned">
                      <Title level={4}>
                        {Car?.car_make} - {Car?.car_model}
                      </Title>
                      <Title level={4}>
                        {summaryService ? summaryService : selectedService}
                      </Title>
                    </div>
                    <hr />
                    <div className="card-content">
                      <Row>
                        <Col xs={12} md={12}>
                          <Title level={4}>Vehicle Color</Title>
                          <p>{Car?.color}</p>
                        </Col>

                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Number</Title>
                          <p>{Car?.plate_number}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Source</Title>
                          <p>{Car?.source}</p>
                        </Col>
                        <Col xs={12} md={12}>
                          <Title level={4}>Plate Code</Title>
                          <p>{Car?.plate_code}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} md={24}>
                          <Title level={4}>Parking Number</Title>
                          <p>
                            {Car?.parking_number ? Car?.parking_number : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </>
              );
            })}
        </Col>
      ),
    },
  ];

  const onChangeTabs = (key) => {
    setActiveTab(key);
  };

  const handleService = (e) => {
    setSelectedService(e.target.value);
  };

  const handleSummaryService = (e) => {
    setSummaryService(e.target.value);
  };

  const handleOkCreateAccount = () => {
    setCreateAccountModal(false);
  };

  const handleCancelCreateAccount = () => {
    setCreateAccountModal(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const updateUserInformation = (e) => {
    if (LoginId != null) {
      e.preventDefault();
      let phoneNumber = e.target?.mobile_no?.value;
      let formatted_number = phoneNumber.replace(" ", "");
      let form = {
        email: e.target.email.value,
        first_name: e.target.first_name.value,
        mobile_no: phone ? phone : formatted_number,
        last_name: e.target.last_name.value,
        building_name: e.target.building_name.value,
        apartment: e.target.apartment.value,
      };
      dispatch(UserService.update_user_information(form))
        .then((response) => {
          setUpdatedInformation(response);
          setUserDetails(response);
          setIsModalOpen(false);
          setSummaryModal(true);
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          setIsModalOpen(false);
        });
    } else {
      // setUpdatedInformation(form);
      e.preventDefault();
      let phoneNumber = e.target?.mobile_no?.value;
      let formatted_number = phoneNumber.replace(" ", "");
      let form = {
        email: e.target.email.value,
        first_name: e.target.first_name.value,
        mobile_no: phone ? phone : formatted_number,
        last_name: e.target.last_name.value,
        building_name: e.target.building_name.value,
        apartment: e.target.apartment.value,
      };
      setUpdatedInformation(form);
      setIsModalOpen(false);
      setSummaryModal(true);
    }
  };

  return (
    <>
      {/* Loader */}
      {loader === true ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <Spin />
        </Backdrop>
      ) : (
        ""
      )}
      {/* User Details Form */}
      {LoginId !== null &&
        ((existingSummaryData?.apartment !== undefined &&
          existingSummaryData?.apartment !== "N/A") ||
          existingCustomerData?.apartment !== undefined) ? (
        <>
          <Row className="summaryModalNEW">
            <Col xs={24} md={24}>
              <div className="modalHeader">
                <Title level={4}>Summary</Title>
                <Button onClick={handleCancel}>X</Button>
              </div>
            </Col>
            <Col xs={24} md={24}>
              <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={onChangeTabs}
              />
            </Col>
            {existingSummaryCar.length <= 0 ? (
              <Col xs={24} md={24} className="modalFooter">
                <div className="summaryBtnSection">
                  <Button className="addVehicle" onClick={carForm}>
                    Add Vehicle
                  </Button>
                </div>
              </Col>
            ) : (
              <>
                <Col xs={24} md={24}>
                  <div className="dropdownWrapper">
                    <select
                      name="service"
                      className="customSelect form-select"
                      onChange={(e) => handleSummaryService(e)}
                      required
                      defaultValue={renderServiceName(summaryService)}
                    >
                      <option value="" selected>
                        Please Select Your Car Wash Service
                      </option>
                      {servicesList?.map((item) => (
                        <option className="OptionList" value={item.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {renderServiceName(summaryService) === 'custom Wash' || renderServiceName(summaryService) === 'Custom Wash' ? (
                      <div className="infoMsg">
                        <p>
                          <InfoCircleOutlined />
                          One of our team will be in touch to confirm your
                          request
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xs={24} md={24} className="termsCheckboxx mt-4" style={{ textAlign: 'center' }}>
                  <Checkbox
                    onChange={onChangeTermsCheckbox}
                    required={true}
                    checked={termsChecked}
                  ></Checkbox>
                  I have read and agree to Kenzie's{" "}
                  <span className="redText" onClick={openPrivacyModal}>
                    Privacy Laws
                  </span>{" "}
                  and{" "}
                  <span className="redText" onClick={openTermsModal}>
                    Terms & Conditions *
                  </span>
                </Col>
                <Col xs={24} md={24} className="modalFooter">
                  <div className="summaryBtnSection">
                    <Button
                      className="edit"
                      onClick={activeTab == 2 ? carForm : editPersonalDetails}
                    >
                      Edit
                    </Button>
                    <Button
                      className={
                        termsChecked === false
                          ? "disabledConfirmBtn"
                          : "confirm"
                      }
                      onClick={paymentSection}
                      disabled={!termsChecked}
                    >
                      Confirm
                    </Button>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </>
      ) : (
        <div className="detailSection">
          <form onSubmit={checking}>
            <Col xs={24} md={24}>
              <div className="modalHeader">
                <Title level={4}>Please fill in the following details:</Title>
                <input
                  type="reset"
                  className="CloseBtn"
                  onClick={CloseForm}
                  value="X"
                />
              </div>
              <hr />
            </Col>
            <Row className="splitRow">
              <Col xs={24} md={12}>
                <label className="inputLabel">
                  Email<span className="star">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Enter your email here"
                  name="email"
                  onBlur={(e) => verifyEmail(e)}
                  required
                  defaultValue={UserEmail ? UserEmail : email ? email : ""}
                  disabled={LoginId != null}
                />
              </Col>
              <Col xs={24} md={11}>
                <label className="inputLabel">
                  Mobile<span className="star">*</span>
                </label>
                <Row>
                  <Col xs={20} md={20}>
                    <PhoneInput
                      defaultCountry="AE"
                      placeholder="Enter phone number"
                      value={phone || phoneDefaultValue}
                      name="mobile_no"
                      required
                      onChange={handlePhoneChange}
                      limitMaxLength={10}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="splitRow">
              <Col xs={24} md={12}>
                <label className="inputLabel">
                  First Name<span className="star">*</span>
                </label>
                <input
                  placeholder="First Name"
                  name="first_name"
                  defaultValue={
                    emailData?.first_name ? emailData?.first_name : ""
                  }
                  required
                />
              </Col>
              <Col xs={24} md={11}>
                <label className="inputLabel">
                  Last Name<span className="star">*</span>
                </label>
                <input
                  placeholder="Last Name"
                  name="last_name"
                  defaultValue={
                    emailData?.last_name ? emailData?.last_name : ""
                  }
                  required
                />
              </Col>
            </Row>
            <Row className="splitRow">
              <Col xs={24} md={12}>
                <label className="inputLabel">
                  Building Name<span className="star">*</span>
                </label>
                <select
                  className="form-select customSelect"
                  name="building_name"
                  required
                  key={key}
                  defaultValue={
                    emailData?.building_name ? emailData?.building_name : ""
                  }
                  disabled={
                    emailData?.building_name != undefined &&
                    emailData?.building_name != "N/A"
                  }
                >
                  <option value="">Select building</option>
                  {buildingData?.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.name}
                        id={e.id}
                        className="OptionList"
                      >
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col xs={24} md={11}>
                <label className="inputLabel">
                  Apartment/Villa<span className="star">*</span>
                </label>
                <input
                  placeholder="Apartment Number"
                  name="apartment"
                  defaultValue={
                    emailData?.apartment ? emailData?.apartment : ""
                  }
                  required
                />
              </Col>
            </Row>
            <Col xs={24} md={24} className="buttonSection">
              <input
                type="submit"
                value="Add Vehicle"
                name="submit"
                className="paynowBtn"
                disabled={disabledButton}
              />
            </Col>
          </form>
        </div>
      )}

      {/* Existing User Passowrd modal */}
      <Modal
        open={existingUserPassword}
        onOk={handleOKExistingUserPassowrd}
        onCancel={handleCancelExistingUserPassowrd}
        closable={false}
        footer={null}
        className="passwordModal"
      >
        {loader === true ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <Spin />
          </Backdrop>
        ) : (
          " "
        )}
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Account Already Exists</Title>
              <Button onClick={handleCancelExistingUserPassowrd}>X</Button>
            </div>
          </Col>
          <Row>
            <Col xs={24} md={24} className="alreadyExistText">
              <p>
                The entered e-mail address already exists. Please autofill your
                details or use a different e-mail address.
              </p>
            </Col>
          </Row>

          {googleLoginUser === true ? (
            <Col xs={24} md={24}>
              <div className="LoginbtnWrapper">
                {googleUrl && (
                  <a
                    href={googleUrl}
                    // className="sclLogBtn fbIcn"
                    onClick={googleLogin}
                  >
                    <button type="button" className="login-with-google-btn">
                      Continue with Google
                    </button>
                  </a>
                )}
              </div>
            </Col>
          ) : (
            <Col xs={24} md={24}>
              <Form
                onFinish={verifyPassword}
                onFinishFailed={onFinishFailedExistingPassword}
                autoComplete="off"
                name="passwordForm"
              >
                <label className="inputLabel">Password</label>
                <Form.Item
                  name="existingPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item className="buttonSection">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="changePwdBtn"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <div className="forgotSection">
                <Button className="forgotPwdBtn" onClick={forgotPassword}>
                  Forgot Password?
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Modal>

      {/* Car Modal  */}
      <Modal
        open={CarModal}
        onOk={handleOkCar}
        onCancel={handleCancelCar}
        closable={false}
        footer={null}
        className="CarModal"
      >
        {loader === true ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <Spin />
          </Backdrop>
        ) : (
          " "
        )}
        <div className="carDetailsForm">
          <Row>
            <Col xs={24} md={24} className="modalHeaderSection">
              <div className="modalHeader">
                <AiOutlineArrowLeft
                  className="backIcon"
                  onClick={detailsForm}
                />
                <Title level={4}>Please fill in your vehicle details:</Title>
                <Button onClick={CloseCarForm}>X</Button>
              </div>
              <hr />
            </Col>
          </Row>
          <form onSubmit={SaveUserData} key={key}>
            <Col xs={24} md={24}>
              <label className="inputLabel">
                Select Your Car Wash Service
                <span className="star">*</span>
              </label>
              <select
                name="service"
                className="customSelect form-select"
                onChange={(e) => handleService(e)}
                required
                value={selectedService}
                defaultValue={
                  existingSummaryData ? existingSummaryData?.service : ""
                }
              >
                <option value="" selected>
                  Please Select Your Car Wash Service
                </option>
                {servicesList?.map((item) => (
                  <option className="OptionList" value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
              {selectedService === "Custom Wash" ? (
                <div className="infoMsg">
                  <p>
                    <InfoCircleOutlined />
                    One of our team will be in touch to confirm your request
                  </p>
                </div>
              ) : (
                ""
              )}
            </Col>
            {car?.map((vehicle, i) => {
              return (
                <>
                  <Row className="splitRow">
                    <input
                      type="hidden"
                      name="id"
                      //key={key}
                      defaultValue={vehicle?.id ? vehicle?.id : ""}
                      required
                      onBlur={(e) => handleInputChange(e, i)}
                    />
                    <Col xs={24} md={12}>
                      <label className="inputLabel">
                        Vehicle Make<span className="star">*</span>
                      </label>
                      <select
                        name="car_make"
                        className="customSelect form-select"
                        value={selectedCar[i]}
                        onChange={(e) => CheckVehicle(e, i)}
                        required
                        //key={key}
                        defaultValue={
                          vehicle?.car_make ? vehicle?.car_make : ""
                        }
                        placeholder="Please Select Vehicle"
                      >
                        <option value="" selected>
                          Please Choose Vehicle
                        </option>
                        {carList.map((e) => {
                          return (
                            <option
                              //key={key}
                              value={e.name}
                              className="OptionList"
                            >
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                    <Col xs={24} md={11}>
                      <label className="inputLabel">
                        Vehicle Model<span className="star">*</span>
                      </label>
                      <select
                        name="car_model"
                        className="customSelect form-select"
                        value={selectedCarModal[i]}
                        onChange={(e) => checkModel(e, i)}
                        defaultValue={
                          vehicle?.car_model ? vehicle?.car_model : ""
                        }
                      >
                        <option value="" selected>
                          Please Choose Vehicle Model
                        </option>
                        {vehicleModel[i]
                          ? Object.keys(vehicleModel[i]).map((e) => {
                            return (
                              <option
                                value={vehicleModel[i][e].name}
                                className="OptionList"
                              >
                                {vehicleModel[i][e].name}
                              </option>
                            );
                          })
                          : ""}
                      </select>
                    </Col>
                  </Row>
                  <Row className="splitRow">
                    <Col xs={24} md={11}>
                      <label className="inputLabel">
                        Color<span className="star">*</span>
                      </label>
                      <input
                        placeholder="Please Mention Vehicle Color"
                        name="color"
                        defaultValue={vehicle?.color ? vehicle?.color : ""}
                        required
                        onBlur={(e) => handleInputChange(e, i)}
                      />
                    </Col>
                    <Col xs={24} md={11}>
                      <label className="inputLabel">
                        Plate Number<span className="star">*</span>
                      </label>
                      <Input
                        placeholder="Please Enter Plate Number"
                        name="plate_number"
                        defaultValue={
                          vehicle?.plate_number ? vehicle?.plate_number : ""
                        }
                        required
                        onBlur={(e) => handleInputChange(e, i)}
                      />
                    </Col>
                  </Row>
                  <Row className="splitRow">
                    <Col xs={24} md={12}>
                      <label className="inputLabel">
                        Plate Source<span className="star">*</span>
                      </label>
                      <select
                        name="source"
                        className="form-select customSelect form-select"
                        onChange={(e) => handleSource(e, i)}
                        value={selectedSource[i]}
                        // key={key}
                        required
                        defaultValue={vehicle?.source ? vehicle?.source : ""}
                      >
                        <option value="" selected>
                          Please Choose Vehicle Plate Source
                        </option>
                        {plateSource.map((e) => {
                          return (
                            <option
                              //  key={key}
                              value={e.name}
                              className="OptionList"
                            >
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                    <Col xs={24} md={11}>
                      <label className="inputLabel">
                        Plate Code<span className="star">*</span>
                      </label>
                      <select
                        name="plate_code"
                        className="customSelect form-select"
                        // key={key}
                        value={selectedCode[i]}
                        onChange={(e) => handleCode(e, i)}
                        required
                        defaultValue={
                          vehicle?.plate_code ? vehicle?.plate_code : ""
                        }
                      >
                        <option value="" selected>
                          Please Choose Vehicle Plate Code
                        </option>
                        {plateCode[vehicle?.source]?.name?.map((e) => (
                          <option
                            value={e.name}
                            className="OptionList"
                          >
                            {e.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row className="splitRow">
                    <Col xs={24} md={24}>
                      <label className="inputLabel">Parking Number</label>
                      <input
                        placeholder="Parking Number"
                        className="mt-2"
                        name="parking_number"
                        defaultValue={
                          vehicle?.parking_number ? vehicle?.parking_number : ""
                        }
                        onBlur={(e) => handleInputChange(e, i)}
                      />
                    </Col>
                  </Row>
                  <div className="btn-box">
                    {car.length !== 1 && (
                      <button
                        className="mr10 removeBtn"
                        type="button"
                        id={i}
                        onClick={() => handleRemoveClick(i)}
                      >
                        Remove
                      </button>
                    )}
                    {car.length - 1 === i && (
                      <button onClick={handleAddClick} className="addBtn">
                        Add Another Vehicle
                      </button>
                    )}
                  </div>
                </>
              );
            })}
            {checkPassword === null || checkPassword === "false" ? (
              <div className="bottomSection">
                <Form.Item name="remember">
                  <Checkbox
                    className="checkbox"
                    onChange={onChangeRemember}
                    checked={rememberMe}
                    disabled={!selectedService}
                  >
                    Remember Me
                  </Checkbox>
                </Form.Item>
              </div>
            ) : (
              ""
            )}
            {LoginId === null ? (
              <Row>
                <Col xs={12} md={12} className="buttonSectionLeft">
                  <input
                    type="submit"
                    value="Confirm & Pay"
                    name="submit"
                    id="Pay"
                    className="paynowBtn"
                  />
                </Col>
                <Col xs={12} md={12} className="buttonSectionRight">
                  <input
                    type="submit"
                    value="Confirm & Save"
                    name="submit"
                    id="Save"
                    className="paynowBtn"
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={24} md={24} className="buttonSectionLeft middleButton">
                  <input
                    type="submit"
                    value={activeTab == 2 ? "Update" : "Confirm & Pay"}
                    name="submit"
                    id="Pay"
                    className="paynowBtn"
                  />
                </Col>
              </Row>
            )}
          </form>
        </div>
      </Modal>

      {/* Payment Modal */}
      <Modal
        open={paymentModal}
        onOk={handleOkPayment}
        onCancel={handleCancelPayment}
        closable={false}
        footer={null}
        className="CarModal"
      >
        <div className="paymentWrapper">
          <div className="backBtnSection">
            <AiOutlineArrowLeft
              onClick={LoginId ? loggedSummaryForm : summaryForm}
            />
          </div>
          <div className="closeBtnSection">
            <Button onClick={ClosPaymentForm}>X</Button>
          </div>
          {loader === true ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <Spin />
            </Backdrop>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit} className="StripeForm">
            <Row>
              <Col xs={24} md={10}>
                <div className="leftSection">
                  <div className="Amount">
                    <label className="label">Enter Amount:</label>
                    <input
                      name="amount"
                      type="number"
                      onChange={updateData}
                      required
                    />
                    <div className="tipInfo">
                      <InfoCircleOutlined />
                      <Title level={4}>
                        This total amount is excluding 5% VAT.
                      </Title>
                    </div>
                  </div>
                  <div className="Tip">
                    <label className="label">Tip Amount:</label>
                    <input
                      name="tip"
                      type="number"
                      onChange={updateData}
                      min="0"
                    />
                    <div className="tipInfo">
                      <InfoCircleOutlined />
                      <Title level={4}>
                        100% of the tip goes to your cleaner directly. Thank you
                        for your generosity!
                      </Title>
                    </div>
                  </div>
                  <div className="Message">
                    <textarea
                      className="customTextarea"
                      name="message"
                      rows={6}
                      placeholder="Please specify the services you are paying for, including the service month(s), number of cars, service package, and any other relevant details."
                      onChange={updateData}
                      maxLength={100}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={14}>
                <div className="rightSection">
                  <div className="buttonSection">
                    <div>
                      <PaymentForm
                        userDetails={userDetails}
                        project_name={project_name}
                        emirate_name={emirate_name}
                        description={description}
                        Amount={actualAmount}
                        Tip={actualTip}
                        service={summaryService}
                      />
                    </div>
                  </div>
                  <div className="OrSection">
                    <span>OR</span>
                  </div>
                  <hr />
                  <div className="StripeHeader">
                    <Title level={4}>Enter your Card details</Title>
                  </div>
                  <div className="row stripeFormDetails">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="cc-name">Name on card</label>
                      <input
                        id="cc-name"
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="cc-email">Email</label>
                      <input
                        id="cc-email"
                        type="text"
                        className="form-control"
                        value={UserEmail ? UserEmail : email ? email : ""}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row stripeFormDetails">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="cc-number">Card Number</label>
                      <CardNumberElement
                        id="cc-number"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                        required
                      />
                    </div>
                  </div>
                  <div className="row stripeFormDetails">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="expiry">Valid Upto</label>
                      <CardExpiryElement
                        id="expiry"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="cvv">CVV</label>
                      <CardCvcElement
                        id="cvc"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                        required
                      />
                    </div>
                  </div>
                  <Row className="stripeFormDetails totalAmountRow">
                    <Col md={24} xs={24}>
                      <label>Total Amount</label>
                      <div
                        className={
                          totalAmount
                            ? "AmountAdded totalAmountSection"
                            : "totalAmountSection"
                        }
                      >
                        {totalAmount ? totalAmount : "Total Amount"}
                      </div>
                      <div className="infoCol">
                        <InfoCircleOutlined />
                        Total Amount after adding tip and VAT.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <div className="reminderCheckboxWrapper">
                        <Checkbox onChange={onChangeReminder} checked={checked}>I would like to receive a monthly email reminder for my upcoming payment due date.</Checkbox>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mb-4" />
                  <div className="bottomBtnContainer">
                    {loader ? (
                      <button className="btn btn-dark paymentBtn" disabled>
                        <div
                          className="spinner-border spinner-border-sm text-light "
                          style={{ marginRight: "0.5rem" }}
                          role="status"
                        ></div>
                        Processing...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-dark paymentBtn"
                        disabled={paymentDisabled}
                      >
                        Confirm & Pay
                      </button>
                    )}
                  </div>
                  {errorMsg && (
                    <div className="text-danger mt-2">{errorMsg}</div>
                  )}
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>

      {/* Password Modal */}
      <Modal
        open={passwordModal}
        onOk={handleOkPassword}
        onCancel={handleCancelPassword}
        closable={false}
        footer={null}
        maskClosable={false}
        className="passwordModal"
      >
        {loader === true ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <Spin />
          </Backdrop>
        ) : (
          " "
        )}
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Please Add a Password</Title>
              <Button onClick={handleCancelPassword}>X</Button>
            </div>
          </Col>
          <Col xs={24} md={24}>
            <Form
              onFinish={onFinishPassword}
              onFinishFailed={onFinishFailedPassword}
              autoComplete="off"
              name="passwordForm"
            >
              <div>
                <label className="inputLabel">Password</label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </div>
              <div className="confirmPwdWrapper">
                <label className="inputLabel">Confirm Password</label>
                <Form.Item
                  name="confirm_password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </div>
              <Form.Item className="buttonSection">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="changePwdBtn"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* Summary Modal */}
      <Modal
        open={summaryModal}
        onOk={handleOkSummary}
        onCancel={handleCancelSummary}
        closable={false}
        footer={null}
        className="summaryModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <AiOutlineArrowLeft className="backIcon" onClick={carForm} />
              <Title level={4}>Summary</Title>
              <Button onClick={CloseSummaryForm}>X</Button>
            </div>
          </Col>
          <Col xs={24} md={24}>
            <Tabs
              defaultActiveKey="1"
              items={NewUserItems}
              onChange={onChangeTabs}
            />
          </Col>
          <Col xs={24} md={24}>
            <div className="serviceDropdown">
              <select
                name="service"
                className="customSelect form-select"
                onChange={(e) => handleSummaryService(e)}
                required
                value={renderServiceName(summaryService)}
              >
                <option value="" selected>
                  Please Select Your Car Wash Service
                </option>
                {servicesList?.map((item) => (
                  <option className="OptionList" value={item.list}>
                    {item?.name}
                  </option>
                ))}
              </select>
              {summaryService === "Custom Wash" ? (
                <div className="infoMsg">
                  <p>
                    <InfoCircleOutlined />
                    One of our team will be in touch to confirm your request
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col xs={24} md={24}>
            {checkPassword === null || checkPassword === "false" ? (
              <Row className="createAccountCheckbox">
                <Col xs={18} md={18} className="leftCol">
                  <Checkbox onChange={onChange} checked={rememberMe}>
                    Create An Account
                  </Checkbox>
                </Col>
                <Col xs={6} md={6} className="rightCol">
                  <Tooltip title="It takes 60 second & will save you a lot of time for your next payment.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
            ) : (
              " "
            )}
            <div className="termsCheckbox mt-4" style={{ textAlign: 'center' }}>
              <Checkbox
                onChange={onChangeTermsCheckbox}
                required={true}
                checked={termsChecked}
              ></Checkbox>
              <div className="infotext">
                I have read and agree to Kenzie's{" "}
                <span className="redText" onClick={openPrivacyModal}>
                  Privacy Laws
                </span>{" "}
                and{" "}
                <span className="redText" onClick={openTermsModal}>
                  Terms & Conditions *
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={24} className="modalFooter">
            <div className="summaryBtnSection">
              <Button
                className="edit"
                onClick={activeTab == 2 ? carForm : editPersonalDetails}
              >
                Edit
              </Button>
              <Button
                className={
                  termsChecked === false ? "disabledConfirmBtn" : "confirm"
                }
                onClick={paymentSection}
                disabled={!termsChecked}
              >
                Confirm
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>

      {/* Privacy Modal */}
      <Modal
        open={privacyModal}
        onOk={handleOkPrivacy}
        onCancel={handleCancelPrivacy}
        closable={false}
        footer={null}
        className="privacyModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Privacy Policy</Title>
              <Button onClick={handleCancelPrivacy}>X</Button>
            </div>
          </Col>
          <Col xs={24} md={24} className="privacy-body">
            <p className="mt-4">
              At Kenzie’s Car Wash (Kenzie Building Cleaning Services EST), we
              are committed to protecting your privacy and ensuring the security
              of any personal information you provide to us. This Privacy Policy
              explains how we collect, use, and protect the personal information
              of our customers when they make payments through our payment
              gateway. Information We Collect: When you make a payment through
              our payment gateway, we may collect certain personal information
              from you, such as your name, address, email address, phone number,
              and payment information (e.g., credit card number, billing
              address, and expiration date). This information is necessary to
              process your payment and complete your transaction.
            </p>
            <Collapse
              onChange={onChangePrivacyCollapse}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
            >
              <Panel header="How We Use Your Information?" key="1">
                <p>
                  We use the personal information we collect from you to process
                  your payment and complete your transaction. We may also use
                  your information to communicate with you about your payment or
                  to send you information about Kenizes Car Wash and our
                  products or services. We do not share your personal
                  information with any third parties for marketing purposes.
                </p>
              </Panel>
              <Panel header="Security Measures" key="2">
                <p>
                  We take the security of your personal information seriously
                  and use appropriate technical and organizational measures to
                  protect it against unauthorized access, disclosure,
                  alteration, or destruction. We use SSL encryption to secure
                  all payment transactions and protect your payment information.
                </p>
              </Panel>
              <Panel header="Cookies" key="3">
                <p>
                  We may use cookies to collect certain information about your
                  visit to our payment gateway, such as your IP address, browser
                  type, and operating system. This information helps us improve
                  our payment gateway and provide a better user experience. You
                  can choose to disable cookies in your browser settings if you
                  prefer not to allow us to collect this information.
                </p>
              </Panel>
              <Panel header="Changes to Our Privacy Policy" key="4">
                <p>
                  We reserve the right to update or change this Privacy Policy
                  at any time. Any changes will be posted on this page, and the
                  revised Privacy Policy will be effective immediately upon
                  posting.
                </p>
              </Panel>
              <Panel header="Contact Us" key="5">
                <p>
                  If you have any questions or concerns about our Privacy Policy
                  or how we handle your personal information, please contact us
                  at info@kenzieclean.ae
                </p>
              </Panel>
            </Collapse>
            <div className="newPage">
              <a href="//www.kenzieclean.ae/privacy" target="_blank">
                Read More
              </a>
            </div>
          </Col>
          <Col xs={24} md={24} className="modalFooter">
            <Button onClick={handleOkPrivacy} className="btn acceptBtn">
              I Accept
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Terms Modal */}
      <Modal
        open={termsModal}
        onOk={handleOkTerms}
        onCancel={handleCancelTerms}
        closable={false}
        footer={null}
        className="privacyModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Terms & Conditions</Title>
              <Button onClick={handleCancelTerms}>X</Button>
            </div>
          </Col>
          <Col xs={24} md={24} className="privacy-body">
            <p className="mt-4">
              At (Kenzie Building Cleaning Services EST) Trading as Kenzie’s Car
              Wash, we want to ensure a smooth and secure payment experience for
              our customers. Please read the following Terms and Conditions
              carefully before using our payment gateway app:
            </p>
            <Collapse
              onChange={onChangePrivacyCollapse}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
            >
              <Panel header="Payment Processing" key="1">
                <p>
                  By using our payment gateway app, you agree that Kenzie’s Car
                  Wash will process your payment through the payment method you
                  have selected. We reserve the right to reject or cancel any
                  payment for any reason, including if we suspect fraud or if
                  the payment violates any applicable law or regulation.
                </p>
              </Panel>
              <Panel header="Payment Information" key="2">
                <p>
                  You agree to provide accurate and complete payment information
                  when making a payment through our payment gateway app. You are
                  responsible for ensuring that the payment information you
                  provide is valid, current, and not fraudulent. We reserve the
                  right to suspend or terminate your use of our payment gateway
                  app if we suspect that the payment information you provide is
                  inaccurate, fraudulent, or otherwise violates these Terms and
                  Conditions.
                </p>
              </Panel>
              <Panel header="Security" key="3">
                <p>
                  We take the security of your payment information seriously and
                  use industry-standard security measures to protect it.
                  However, we cannot guarantee that our payment gateway app is
                  completely secure and free from unauthorized access, hacking,
                  or other security breaches. You agree to use our payment
                  gateway app at your own risk and to take appropriate measures
                  to protect your payment information, such as using strong
                  passwords and not sharing your payment information with
                  others.
                </p>
              </Panel>
              <Panel header="Fees" key="4">
                <p>
                  Kenzie’s Car Wash may charge fees for using our payment
                  gateway app. These fees may vary depending on the payment
                  method you choose, the amount of your payment, and other
                  factors. By using our payment gateway app, you agree to pay
                  any applicable fees and to be bound by our fee schedule, which
                  may change from time to time.
                </p>
              </Panel>
              <Panel header="Refunds" key="5">
                <p>
                  If you are entitled to a refund for any reason, Kenzie’s Car
                  Wash will process the refund in accordance with our refund
                  policy. We reserve the right to refuse a refund if we suspect
                  fraud or if the refund violates any applicable law or
                  regulation.
                </p>
              </Panel>
              <Panel header="Limitation of Liability" key="6">
                <p>
                  If you are entitled to a refund for any reason, Kenzie’s Car
                  Wash will process the refund in accordance with our refund
                  policy. We reserve the right to refuse a refund if we suspect
                  fraud or if the refund violates any applicable law or
                  regulation.
                </p>
              </Panel>
              <Panel header="Governing Law" key="7">
                <p>
                  These Terms and Conditions are governed by and construed in
                  accordance with the laws of the jurisdiction where Kenzie’s
                  Car Wash is located. Any disputes arising from your use of our
                  payment gateway app will be resolved in accordance with the
                  dispute resolution procedures set forth in our Terms of
                  Service.
                </p>
              </Panel>
            </Collapse>
            <div className="newPage">
              <a href="//www.kenzieclean.ae/terms" target="_blank">
                Read More
              </a>
            </div>
            <p>
              By using our payment gateway app, you agree to be bound by these
              Terms and Conditions. If you do not agree to these Terms and
              Conditions, do not use our payment gateway app.
            </p>
          </Col>
          <Col xs={24} md={24} className="modalFooter">
            <Button onClick={handleOkTerms} className="btn acceptBtn">
              I Accept
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Existing Customer Modal */}
      <Modal
        open={createAccountModal}
        onOk={handleOkCreateAccount}
        onCancel={handleCancelCreateAccount}
        closable={false}
        maskClosable={false}
        footer={null}
        className="existingCustomerModel"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Create an account</Title>
              <Button onClick={handleCancelCreateAccount}>X</Button>
            </div>
          </Col>
          <Col xs={24} md={24} className="modal-body">
            <Title level={4}>
              Looks like you have already used our services. Please create an
              account with us to save time on your next visit.
            </Title>
            <div className="modalButtonSection">
              <Button onClick={setPasswordModal}>Create an account</Button>
              <Button onClick={handleCancelCreateAccount}>Skip</Button>
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className="DetailsForm"
      >
        <div className="detailSection">
          <form onSubmit={updateUserInformation}>
            <Col xs={24} md={24}>
              <div className="modalHeader">
                <Title level={4}>Please fill in the following details:</Title>
                <input
                  type="reset"
                  className="CloseBtn"
                  onClick={CloseDetailForm}
                  value="X"
                />
              </div>
              <hr />
            </Col>
            <Row className="splitRow">
              <Col xs={24} md={12}>
                <label className="inputLabel">
                  Email<span className="star">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Enter your email here"
                  name="email"
                  onBlur={(e) => verifyEmail(e)}
                  required
                  defaultValue={UserEmail ? UserEmail : email ? email : ""}
                  disabled={LoginId != null}
                />
              </Col>
              <Col xs={24} md={11}>
                <label className="inputLabel">
                  Mobile<span className="star">*</span>
                </label>
                <Row>
                  <Col xs={20} md={20}>
                    <PhoneInput
                      defaultCountry="AE"
                      placeholder="Enter phone number"
                      value={phone || phoneDefaultValue} // Set the default value if `phone` is empty
                      name="mobile_no"
                      required
                      onChange={handlePhoneChange}
                      limitMaxLength={10}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="splitRow">
              <Col xs={24} md={12}>
                <label className="inputLabel">
                  First Name<span className="star">*</span>
                </label>
                <input
                  placeholder="First Name"
                  name="first_name"
                  defaultValue={
                    userDetails
                      ? userDetails?.first_name
                      : emailData?.first_name
                        ? emailData?.first_name
                        : updatedInformation
                          ? updatedInformation?.first_name
                          : summaryData
                            ? summaryData?.first_name
                            : ""
                  }
                  required
                />
              </Col>
              <Col xs={24} md={11}>
                <label className="inputLabel">
                  Last Name<span className="star">*</span>
                </label>
                <input
                  placeholder="Last Name"
                  name="last_name"
                  defaultValue={
                    userDetails
                      ? userDetails?.last_name
                      : emailData?.last_name
                        ? emailData?.last_name
                        : updatedInformation
                          ? updatedInformation?.last_name
                          : ""
                  }
                  required
                />
              </Col>
            </Row>
            <Row className="splitRow">
              <Col xs={24} md={12}>
                <label className="inputLabel">
                  Building Name<span className="star">*</span>
                </label>
                <select
                  className="form-select customSelect"
                  name="building_name"
                  required
                  key={key}
                  defaultValue={
                    userDetails
                      ? userDetails?.building_name
                      : emailData?.building_name
                        ? emailData?.building_name
                        : updatedInformation
                          ? updatedInformation?.building_name
                          : ""
                  }
                  disabled={emailData?.building_name}
                >
                  <option value="">Select building</option>
                  {buildingData?.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.name}
                        id={e.id}
                        className="OptionList"
                      >
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col xs={24} md={11}>
                <label className="inputLabel">
                  Apartment/Villa<span className="star">*</span>
                </label>
                <input
                  placeholder="Apartment Number"
                  name="apartment"
                  defaultValue={
                    userDetails
                      ? userDetails?.apartment
                      : emailData?.apartment
                        ? emailData?.apartment
                        : updatedInformation
                          ? updatedInformation?.apartment
                          : ""
                  }
                  required
                />
              </Col>
            </Row>
            <Col xs={24} md={24} className="buttonSection">
              <input
                type="submit"
                value="Update"
                name="submit"
                className="paynowBtn"
              />
            </Col>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DetailsForm;
