import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Typography, Button, Modal, Card, Image, Spin } from "antd";
import { TfiWorld } from "react-icons/tfi";
import { IoLocationOutline } from "react-icons/io5";
import DetailsForm from "../Components/Form/DetailsForm";
import Menu from "../Components/Menu";
import { image } from "../Helper";
import * as UserService from "../Services/UserService/UserService";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate, useLocation } from "react-router-dom";
import LoginLogo from "../Assets/Images/LoginLogo.png";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";
import { FcHighPriority } from "react-icons/fc";
import { Url } from "../Helper";
const { Title } = Typography;
const stripePromise = loadStripe(process.env.REACT_APP_CLIENT_LIVE_KEY);

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const [pageDetail, setPageDetail] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [buildingData, setBuildingData] = useState([]);
  const [qrData, setQrData] = useState();
  const [loader, setLoader] = useState(true);
  const [emiratesName, setEmiratesName] = useState();
  const [projectName, setProjectName] = useState();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const [isNewQRModalOpen, setIsNewQRModalOpen] = useState(false);
  const [isNoBuildingPopup, setIsNoBuildingPopup] = useState(false);
  const [singleExecute, setSingleExecute] = useState(true);
  const [projectLogo, setProjectLogo] = useState();
  const QRScanned = queryParams.get("callback");
  localStorage.setItem("QRverify", QRScanned);
  const isLoggedIn = localStorage.getItem("email");
  const [registerModal, setRegisterModal] = useState(false);
  const [googleUrl, setGoogleUrl] = useState(null);
  const [isNoQrData, setIsNoQrData] = useState(false);
  const [blockedModalOpen, setBlockedModalOpen] = useState(false);
  const [newGoogleUser, setNewGoogleuser] = useState(false);
  const [projectDisabled, setProjectDisabled] = useState(false);

  let LoginId = localStorage.getItem("user_id");
  let User_name = localStorage.getItem("user_name");
  let qr_code = localStorage.getItem("QRverify");
  let UserEmail = localStorage.getItem("email");
  // let buildingChanged = localStorage.getItem("building_changed");

  useEffect(() => {
    const logoutTimer = setTimeout(() => {
      sessionStorage.clear();
      localStorage.removeItem('passwordEntered');
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("user_name");
      localStorage.removeItem("token");
      localStorage.removeItem("emailAddress");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("ExistingDataFetching");
      Cookies.remove("passwordEntered");  
      window.location.href = '/login';  
    }, 1800000); 

    const resetLogoutTimer = () => {
      clearTimeout(logoutTimer);
      setTimeout(() => {
        sessionStorage.clear();
        localStorage.removeItem('passwordEntered');
        localStorage.removeItem("user_id");
        localStorage.removeItem("email");
        localStorage.removeItem("user_name");
        localStorage.removeItem("token");
        localStorage.removeItem("emailAddress");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("ExistingDataFetching");
        Cookies.remove("passwordEntered");
        window.location.href = '/login';
      }, 1800000);
    };

    // Add event listeners to reset the timer on user activity
    document.addEventListener('mousemove', resetLogoutTimer);
    document.addEventListener('keypress', resetLogoutTimer);

    return () => {
      clearTimeout(logoutTimer);
      document.removeEventListener('mousemove', resetLogoutTimer);
      document.removeEventListener('keypress', resetLogoutTimer);
    };
  }, []); 
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showMenu = () => {
    setMenuModal(true);
  };

  const handleOkMenu = () => {
    setMenuModal(false);
  };

  const handleCancelMenu = () => {
    setMenuModal(false);
  };

  useEffect(() => {
    if (location?.state?.registerTrue !== undefined) {
      if (
        location?.state?.registerTrue === "true" &&
        projectDisabled === false
      ) {
        setIsModalOpen(true);
      }
    }
  }, [location?.state?.registerTrue, projectDisabled]);

  useEffect(() => {
    fetch(Url + "/api/auth/google", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setGoogleUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    getLandingPageDetails();
  }, []);

  const getLandingPageDetails = () => {
    localStorage?.removeItem('bgImage');
    if (qr_code === "null") {
      dispatch(UserService.getLandingPageDetails()).then((res) => {
        setPageDetail(res.data);
        setBackgroundImage(res?.data?.banner_image);
        localStorage?.setItem('bgImage', res?.data?.banner_image)
        setBuildingData(res?.building_name);
        setLoader(false);
        setOpen(false);
      });
    } else {
      dispatch(UserService.Scanned_value(qr_code)).then((res) => {
        if (Object.keys(res).length === 0) {
          setIsModalOpen(false);
          setIsNoQrData(true);
        } else {
          setIsNoQrData(false);
        }
        if (res.status === 0) {
          setNotFound();
        } else if (res?.status == "deleted") {
          setProjectDisabled(true);
          setIsNoBuildingPopup(false);
          localStorage.setItem("disabled_project", 1);
          setIsModalOpen(false);
        } else { 
          localStorage.setItem("qrData", JSON.stringify(res));
          setQrData(res);
          setBuildingData(res?.building);
          setLoader(false);
          setOpen(false);
          setEmiratesName(res?.emirate?.name);
          setProjectName(res?.project_name);
          Cookies.set("passwordEntered", false, { expires: 1 / 12 });
          localStorage.setItem("disabled_project", 0);
          checkBuilding(res?.building);
          if (
            res?.emirate?.name === undefined ||  res?.emirate?.name === 1 || res?.emirate?.name === '1' || 
            res?.project_name === 1 || res?.project_name === undefined || res?.project_name === '1'
          ) {
            setIsModalOpen(false);
            setIsNoQrData(true);
          } else {
            setIsNoQrData(false);
          }
        }
      });
      dispatch(UserService.getLandingPageDetails()).then((res) => {
        setPageDetail(res?.data);
        setBackgroundImage(res?.data?.banner_image);
        localStorage?.setItem('bgImage', res?.data?.banner_image)
        setProjectLogo(res?.data?.logo);
        setLoader(false);
        setOpen(false);
        Cookies.set("passwordEntered", false, { expires: 1 / 12 });
      });
    }
  };

  const checkBuilding = (data) => {
    if (data === null || data.length === 0 || data === undefined) {
      setIsNoBuildingPopup(true);
      setProjectDisabled(false);
    }
  };

  const setNotFound = () => {
    navigate("/not-found");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SignIn = () => {
    navigate("/login");
  };

  const registerUser = () => {
    setIsModalOpen(true);
    setRegisterModal(false);
  };

  const customerDashboard = () => {
    navigate("/customer_profile");
  };

  const myStyle = {
    backgroundImage: `url(${backgroundImage ? image + backgroundImage : ""})`,
  };

  const resetValues = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("user_name");
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("passwordEntered", false);
    sessionStorage.clear(); //for sessionStorage
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    if (LoginId !== null || singleExecute === true) {
      setOpen(true);
      setLoader(true);
      localStorage.setItem("ExistingDataFetching", true);
      dispatch(UserService.check_existing_user(UserEmail))
        .then((response) => {
          if (response?.data?.original_qr_code === qr_code) {
            localStorage?.setItem("building_changed", 1);
          } else {
            localStorage?.setItem("building_changed", 0);
          }
          qrChecking(response?.data?.original_qr_code);
          localStorage.setItem("userBuilding", response?.data?.building_name);
          setSingleExecute(false);
          setLoader(false);
          setOpen(false);
          if (response?.data?.status === 0) {
            setBlockedModalOpen(true);
            setTimeout(() => {
              localStorage.removeItem("user_id");
              localStorage.removeItem("email");
              localStorage.removeItem("user_name");
              localStorage.removeItem("token");
              localStorage.setItem("isLoggedIn", false);
              localStorage.setItem("passwordEntered", false);
              sessionStorage.clear(); //for sessionStorage
              navigate("/login");
              window.location.reload();
            }, 10000);
          }
          if (LoginId && response?.exists === false) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }
          let checkDisable = localStorage.getItem("disabled_project");
          if (
            response?.data?.building_name === "N/A" &&
            response?.data?.mobile_no === "0" &&
            response?.data?.apartment === "N/A"
          ) {
            setNewGoogleuser(true);
            if (checkDisable == 0) {
              setIsModalOpen(true);
            }
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [projectDisabled, LoginId]);

  let buildingName = localStorage.getItem("userBuilding");

  useEffect(() => {
    if (isLoggedIn) {
      if (buildingData?.length > 0 && buildingName !== undefined && buildingName !== 'undefined') {
        setLoader(true);
        setOpen(true);
        const searchTerm = buildingName;
        let searchRes = buildingData?.find((o) => o.name === searchTerm);
        if (searchRes === undefined && newGoogleUser === false && searchTerm !== 'N/A') {
          setIsNewQRModalOpen(true);
        } else {
          setIsNewQRModalOpen(false);
        }
        setLoader(false);
        setOpen(false);
      }
    } else {
      localStorage.setItem("passwordEntered", false);
    }
  }, [buildingName, buildingData, isLoggedIn, newGoogleUser]);

  useEffect(() => {
    if (qr_code === "null" || QRScanned === "null") {
      navigate("/");
    }
  }, []);

  const handleOkNewQR = () => {
    setIsNewQRModalOpen(false);
  };

  const qrChecking = (value) => {
    if (LoginId != null && QRScanned !== null) {
      // if (value !== qr_code || buildingChanged === "0") {
      //   setIsNewQRModalOpen(true);
      // }
    }
  };

  const profileSection = () => {
    if (LoginId != null) {
      navigate("/customer_profile");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const removeCookie = setTimeout(() => {
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("user_name");
      localStorage.removeItem("isLoggedIn");
      window.location.reload();
    }, 30 * 60 * 1000);

    // Clear the timeout when the component unmounts
    return () => {
      clearTimeout(removeCookie);
    };
  }, []);

  const googleLogin = () => {
    localStorage.setItem("google_login", "true");
  };

  const handleOkRegister = () => {
    setRegisterModal(false);
  };

  const handleCancelRegister = () => {
    setRegisterModal(false);
  };

  const OpenRegisterModal = () => {
    setRegisterModal(true);
  };
  const handleOkBlocked = () => {
    setBlockedModalOpen(false);
  };

  const handleOkProjectDisabled = () => {
    setProjectDisabled(false);
  };

  const NavigateProfile = () => {
    if (LoginId === null) {
      navigate("/login");
    } else {
      navigate("/customer_profile");
    }
  };
  return (
    <>
      {loader === true ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Spin />
          </Backdrop>
        </>
      ) : (
        <div className="mainWrapper" style={myStyle}>
          {QRScanned === null ? (
            <>
              <div className="NotFoundWrapper">
                <div className="disabledQRSection">
                  <Col xs={24} md={24}>
                    <div className="linkSection">
                      <TfiWorld />
                      <a href="https://kenzieclean.ae/">
                        {pageDetail?.website_link
                          ? pageDetail.website_link
                          : qrData?.website_link}
                      </a>
                    </div>
                  </Col>
                  <Card>
                    <div className="imgWrapper">
                      <Image
                        src={LoginLogo}
                        className="img-fluid"
                        alt="Not found"
                        preview={false}
                      />
                    </div>
                    <Row>
                      <Col xs={24} md={24}>
                        <Title level={2}>Page Not Found!</Title>
                      </Col>
                    </Row>
                    <p>URL is not authenticated. Kindly scan the QR Code.</p>
                  </Card>
                </div>
              </div>
            </>
          ) : (
            <>
              <Container>
                {LoginId != null ? (
                  <Row className="dashboardWrapper">
                    <Col xs={24} md={24}>
                      <Title level={4}>Welcome, {User_name}</Title>
                      <div className="btnsWrapper">
                        <Button
                          className="dashboard"
                          onClick={customerDashboard}
                        >
                          My Profile
                        </Button>
                        <Button
                          className="dashboard logOutBtn"
                          onClick={resetValues}
                        >
                          Log Out
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row className="SignInWrapper">
                    <Col xs={24} md={24}>
                      <Button className="register" onClick={OpenRegisterModal}>
                        Register
                      </Button>
                      <Button className="signIn" onClick={SignIn}>
                        Sign In
                      </Button>
                    </Col>
                  </Row>
                )}
                <Row className="mt-5 mainSection">
                  <Col xs={24} md={24}>
                    <div className="linkSection">
                      <a
                        href="https://kenzieclean.ae/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button>Visit Website</Button>
                      </a>
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="logoSection">
                      {projectLogo ? (
                        <img
                          src={image + projectLogo}
                          alt="Not Found"
                          className="img-fluid logoImage"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="welcomeTextSection">
                      <Title level={4}>
                        {pageDetail?.title ? pageDetail?.title : qrData?.title}
                      </Title>
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="buttonSection">
                      <Button
                        className="btn PayButton"
                        onClick={
                          LoginId === null || LoginId === undefined
                            ? OpenRegisterModal
                            : showModal
                        }
                      >
                        {isLoggedIn
                          ? "Pay Now"
                          : pageDetail?.button_1
                            ? pageDetail?.button_1
                            : qrData?.button_1}
                      </Button>
                      <Button className="btn PayButton" onClick={showMenu}>
                        {pageDetail?.button_2
                          ? pageDetail?.button_2
                          : qrData?.button_2}
                      </Button>
                      <a href="https://wa.me/+971552709113">
                        <Button className="btn PayButton">
                          {pageDetail?.button_3
                            ? pageDetail?.button_3
                            : qrData?.button_3}
                        </Button>
                      </a>
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <Row className="buildingSection">
                      {qrData?.logo ? (
                        <img
                          src={qrData?.logo ? image + qrData.logo : ""}
                          alt="Not Found"
                          className="img-fluid buildingImage"
                        />
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row>
                      <div className="addressSection">
                        <IoLocationOutline />
                        <Title level={4}>
                          {qrData?.project_name
                            ? qrData?.project_name
                            : "Dubai"}
                          ,{" "}
                          {qrData?.emirate?.name
                            ? qrData?.emirate?.name
                            : "Dubai"}
                        </Title>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Container>

              <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                footer={null}
                className="DetailsForm"
              >
                {loader === true ? (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <Spin />
                  </Backdrop>
                ) : (
                  <Elements stripe={stripePromise}>
                    <DetailsForm
                      handleCancel={handleCancel}
                      showModal={showModal}
                      buildingData={buildingData}
                      project_name={projectName}
                      emirate_name={emiratesName}
                    />
                  </Elements>
                )}
              </Modal>

              <Modal
                open={menuModal}
                onOk={handleOkMenu}
                onCancel={handleCancelMenu}
                closable={false}
                footer={null}
                className="MenuModal"
              >
                <Menu handleCancelMenu={handleCancelMenu} qrData={qrData} />
              </Modal>
            </>
          )}
        </div>
      )}

      <Modal
        open={isNewQRModalOpen}
        onOk={handleOkNewQR}
        closable={false}
        maskClosable={false}
        footer={null}
        className="newQrModal"
      >
        <div className="modalHeader">
          <Title level={4}>New QR Detected</Title>
        </div>
        <div className="modal-body">
          <Title level={4}>
            It looks like you are in a new building. Please change your building
            name in Profile section to continue using our services here.
          </Title>
        </div>
        <div className="modalFooter">
          <Button onClick={profileSection} className="profileBtn">
            Go To Profile
          </Button>
        </div>
      </Modal>

      <Modal
        open={isNoBuildingPopup}
        closable={false}
        maskClosable={false}
        footer={null}
        className="newQrModal"
      >
        <div className="modalHeader">
          <Title level={4}>404 Not Found</Title>
        </div>
        <div className="modal-body">
          <Title level={4}>
            The QR code you have scanned no longer exists. However, if you are
            an existing customer, you can still login to view and edit your
            profile.
          </Title>
        </div>
        <div className="modalFooter">
          <Button onClick={profileSection} className="profileBtn">
            Go To Profile
          </Button>
        </div>
      </Modal>

      <Modal
        open={isNoQrData}
        closable={false}
        maskClosable={false}
        footer={null}
        className="noDataModel"
      >
        <div className="modalHeader">
          <Title level={4}>404 Not Found</Title>
        </div>
        <div className="modal-body">
          <div className="icon">
            <FcHighPriority />
          </div>
          <Title level={4}>Unfortunately, this QR code is not valid.</Title>
        </div>
      </Modal>

      <Modal
        open={registerModal}
        onOk={handleOkRegister}
        onCancel={handleCancelRegister}
        closable={false}
        footer={null}
        className="privacyModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>User Registeration</Title>
              <Button onClick={handleCancelRegister}>X</Button>
            </div>
          </Col>
        </Row>
        <div className="modalBody">
          <Row className="registerSection">
            <Col xs={24} md={24}>
              <div className="noramlRegisteration">
                <Button onClick={registerUser}>Continue with email</Button>
              </div>
            </Col>
            <Col xs={24} md={24}>
              <Title level={4}>OR</Title>
            </Col>
            <Col xs={24} md={24}>
              <div className="LoginbtnWrapper">
                {googleUrl && (
                  <a href={googleUrl} onClick={googleLogin}>
                    <button type="button" className="login-with-google-btn">
                      Continue with Google
                    </button>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={blockedModalOpen}
        onOk={handleOkBlocked}
        closable={false}
        footer={null}
        className="blockedModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Account Blocked</Title>
            </div>
          </Col>
        </Row>
        <div className="modalBody">
          <Title level={4}>Your account has been blocked by the Admin.</Title>
          <p>
            {" "}
            You will be logged out of your current session. <br />
            Please contact nearest Kenzie's outlet for more information.
          </p>
        </div>
      </Modal>

      <Modal
        open={projectDisabled}
        onOk={handleOkProjectDisabled}
        closable={false}
        footer={null}
        className="blockedModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>404 Not Found</Title>
            </div>
          </Col>
        </Row>
        <div className="modalBody">
          <p>
            The QR code you have scanned no longer exists. However, if you are
            an existing customer, you can still login to view and edit your
            profile.
          </p>
          <br />

          {LoginId === null ? (
            <Button className="profileButton" onClick={NavigateProfile}>
              Sign In
            </Button>
          ) : (
            <Button className="profileButton" onClick={NavigateProfile}>
              Go To Profile
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Home;
